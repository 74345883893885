(function () {
  'use strict';

  class TopicsRest {
    constructor($resource, env) {
      this.$resource = $resource;
      this.env = env;
    }

    topics() {
      return this.$resource(`${this.env.api}/topics`);
    }

    myTopics() {
      return this.$resource(`${this.env.api}/topics/my-topics`);
    }

    topic() {
      return this.$resource(`${this.env.api}/topics/:id`, {id: '@id'}, {update: {method: 'PUT'}});
    }

    lomMetadata() {
      return this.$resource(`${this.env.api}/topics/:id/lom-metadata`, {id: '@id'});
    }

  }

  /**
   * @ngdoc service
   * @name components.service:TopicsRest
   *
   * @description
   *
   */
  angular
    .module('components')
    .service('TopicsRest', TopicsRest);
}());
